/* CSS for norgeshstorie forsiden */
@import "utils.less";

body.globalnav-smaller #globalnav ul li.vrtx-active-item.parent-folder a {
  border: 2px solid @color-light !important;
}

#vrtx-frontpage {
  a {
    text-decoration: none;
  }
  h1 {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    display: block;
  }
  &.vrtx-frontpage-full-width.total-main #main #total-main {
    width: 100%;
  }
  .grid-container.full-width-picture-bg {
    margin-top: 0;
  }
  #main {
    #bottomnav {
      display: none;
    }
    .vrtx-frontpage-box.full-width-picture {
      .font-size(22);
      font-weight: 400;
      font-family: @font-content;
      line-height: 1.6;
      padding: 30px 40px;
      width: 460px;
      margin: 20px auto;
      background: @color-background;
      color: @color-dark;
      .vrtx-frontpage-box-picture img {
        opacity: 0;
      }
      &.dark {
        background: @color-neutral;
        color: @color-light;
        h2 {
          a {
            color: @color-primary;
            &:hover,
            &:focus {
              color: @color-light;
            }
          }
        }
        .vrtx-more a {
          color: @color-primary;
          border-bottom: 1px solid @color-primary;
          &:after {
            background: url("../images/sprite-arrow-right.svg") no-repeat 11px
              6px !important;
          }
          &:hover,
          &:focus {
            color: @color-light;
            border-color: @color-light;
          }
        }
      }
      &.no-content {
        background-color: transparent;
      }
      h2 {
        margin-top: 0;
        &::after {
          .header-line(@color-primary, 15px, 0);
        }
        a {
          .font-size(30);
          margin: 0 0 20px 0 !important;
          color: @color-dark;
          display: inline;
          border-spacing: 2px;
          text-decoration: none;
          &:hover,
          &:focus {
            color: @color-primary--dark;
            text-decoration: none;
          }
        }
      }
    }

    .vrtx-frontpage-box.vrtx-frontpage-box-picture .vrtx-frontpage-box-picture {
      height: 0;
    }

    .grid-container {
      padding: 0;
      background: transparent;
      margin-bottom: 0px;
      &.full-width-picture-bg {
        height: 100%;
        max-height: 350px;
        min-height: 320px;
        -webkit-animation: fadein 1000ms ease-in both;
        animation: fadein 1000ms ease-in both;
      }

      // featured-item
      &.grid-color-black {
        margin-top: 0px;
        margin-bottom: 20px;
        color: @color-light;
        background-color: @color-neutral !important;
        .row {
          margin-bottom: 0;
          width: @pagewidth-wide;
        }
        .vrtx-frontpage-box {
          background-color: transparent;
        }
      }
    }
    .featured-item {
      min-height: 96px;
      h2 {
        margin-top: 40%;
        a {
          color: @color-primary;
          text-decoration: underline;
        }
      }
    }
    // /* featured-item ^ */
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    .menu-search,
    #search.menu-search {
      margin-top: 0px;
    }
  }
  .not-for-ansatte #footer-wrapper {
    padding: 0px 0;
  }
  #vrtx-frontpage.vrtx-frontpage-full-width.total-main
    #main
    #total-main
    #vrtx-content {
    background: @color-background;
  }
  #vrtx-frontpage #main {
    padding-top: 0;
    margin-top: 0;
    .vrtx-frontpage-box {
      &.full-width-picture {
        padding: 20px 15px;
        width: 100%;
        margin: 0;
        &.content-over-image {
          img {
            width: auto;
            //height: 23rem !important;
            object-fit: cover;
            //margin-top: -69px;
            //display: none;
            display: block;
            opacity: 1;
          }
          .vrtx-box-content {
            border-bottom: none;
            width: 100%;
            margin-top: 0px;
          }
        }
        &.no-content {
          padding: 0;
          width: calc(100vw);
          margin-left: 50%;
          transform: translateX(-50%);
          .vrtx-frontpage-box-picture img {
            opacity: 1;
            width: 100%;
            padding: 0;
            margin: -3rem 0px;
          }
        }
      }
      &.vrtx-frontpage-box-picture .vrtx-frontpage-box-picture {
        height: auto;
        //margin: 0;
      }
    }
    .grid-container {
      &.full-width-picture-bg {
        max-height: initial;
        min-height: 260px;
      }
      &.grid-color-black {
        margin-top: -15px;
        .row {
          width: 100%;
        }
        .vrtx-frontpage-box {
          border-top: none !important;
          margin-bottom: 15px;
          &.featured-item {
            min-height: initial;
            padding: 0px;
            .vrtx-box-content,
            .vrtx-more {
              padding: 20px 20px 0 20px;
              &:hover,
              &:focus {
                background-color: transparent;
              }
              a {
                text-decoration: underline;
                .font-size(16);
              }
            }
          }
        }
      }
    }
    .grid-container:not(.row-last-colored)
      + .grid-container:not(.row-first-colored),
    .grid-container.row-last-colored + .row-first-colored {
      border-top: none;
      width: 100%;
    }

    #vrtx-main-content {
      .vrtx-frontpage-box {
        &.tidslinje {
          margin: 0;
          padding-left: 35px;
          h2 span,
          h2 a {
            padding-right: 40px;
          }
          h2 {
            .font-size(18);
          }
          .periode {
            .font-size(14);
          }
        }
      }
    }
  }
}

@media print {
  #vrtx-frontpage #main {
    .vrtx-frontpage-box.full-width-picture {
      width: 100%;
    }
    .vrtx-frontpage-box.full-width-picture.content-over-image
      .vrtx-box-content {
      background-color: transparent !important;
      width: 100%;
      .font-size(20);
      h2 a {
        color: @color-dark !important;
      }
    }
    .grid-container.grid-color-black {
      display: none;
    }
    .grid-container.full-width-picture-bg {
      width: 100%;
      background: none !important;
      margin-bottom: 20px !important;
    }
  }
}
